import { ref, onValue } from "firebase/database";
import { database } from "../../../firebaseConfig";
import {DrinksMenuProps} from "../../drinks/type";

export const fetchDrinksData = (path: string, callback: (data: DrinksMenuProps) => void) => {
    const drinksRef = ref(database, path);

    onValue(drinksRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            const formattedData: DrinksMenuProps = {
                beer: data.beer || [],
                nonAlcoholic: data.nonAlcoholic || [],
                hotDrinks: data.hotDrinks || [],
                vine: data.vine || [],
                rum: data.rum || [],
                whiskey: data.whiskey || [],
                others: data.others || [],
                snacks: data.snacks || [],
            };
            callback(formattedData);
        }
    });
};
