import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ref, onValue } from "firebase/database";
import {database} from "../firebaseConfig";

interface OpeningHoursData {
    [day: string]: string;
}

// Správné pořadí dnů
const daysOrder = ["Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota", "Neděle"];

const OpeningHours: React.FC = () => {
    const [data, setData] = useState<OpeningHoursData | null>(null);

    useEffect(() => {
        const fetchOpeningHours = () => {
            const openingHoursRef = ref(database, 'openingHours');
            onValue(openingHoursRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setData(data);
                }
            });
        };

        fetchOpeningHours();
    }, []);

    return (
        <section
            id="opening-hours"
            className="bg-light py-4 mx-md-4 mx-0 my-3 rounded shadow-sm"
            style={{
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
        >
            <h2 className="text-center mb-4" style={{ fontSize: '2.5em' }}>
                Otevírací hodiny
            </h2>
            {data ? (
                <ul className="list-unstyled text-center m-0" style={{ fontSize: '1.2em' }}>
                    {daysOrder.map((day) => (
                        <li
                            key={day}
                            style={{
                                margin: '10px 0',
                                padding: '10px 20px',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            {day} - {data[day] || "Zavřeno"}
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-center">Načítání otevírací doby...</p>
            )}
        </section>
    );
};

export default OpeningHours;
