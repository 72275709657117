import {MenuCategory} from './type';
import {MenuProps} from "../../menu/type";

export const mapMenuDataToCategories = (menuData: MenuProps): MenuCategory[] => {
    return [
        {
            categoryName: 'soups',
            items: (menuData.soups || []).map((item, index) => ({
                id: `${item.soup}-${index}`,
                name: item.soup,
                price: item.price,
            })),
        },
        {
            categoryName: 'mainCourses',
            items: (menuData.mainCourses || []).map((item, index) => ({
                id: `${item.mainCourse}-${index}`,
                name: item.mainCourse,
                price: item.price,
            })),
        },
        {
            categoryName: 'desserts',
            items: (menuData.desserts || []).map((item, index) => ({
                id: `${item.dessertName}-${index}`,
                name: item.dessertName,
                price: item.price,
            })),
        },
        {
            categoryName: 'snack',
            items: (menuData.snack || []).map((item, index) => ({
                id: `${item.snackName}-${index}`,
                name: item.snackName,
                price: item.price,
            })),
        }
    ];
};

export const mapCategoriesToMenuData = (categories: MenuCategory[]): MenuProps => {
    const menuData: MenuProps = {
        menu: [],
        soups: [],
        mainCourses: [],
        desserts: [],
        snack: [],
    };

    categories.forEach((category) => {
        switch (category.categoryName) {
            case 'soups':
                menuData.soups = category.items.map((item) => ({
                    soup: item.name,
                    price: item.price,
                }));
                break;
            case 'mainCourses':
                menuData.mainCourses = category.items.map((item) => ({
                    mainCourse: item.name,
                    price: item.price,
                }));
                break;
            case 'desserts':
                menuData.desserts = category.items.map((item) => ({
                    dessertName: item.name,
                    price: item.price,
                }));
                break;
            case 'snack':
                menuData.snack = category.items.map((item) => ({
                    snackName: item.name,
                    price: item.price,
                }));
                break;
            default:
                break;
        }
    });

    return menuData;
};
