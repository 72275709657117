import React from 'react';
import OpeningHours from "../OpeningHours";
import {Col, Row} from 'react-bootstrap';
import GoogleMap from "./googleMaps/GoogleMap";
import ActionInfoField from "../actionInfo/ActionInfoField";

const About: React.FC = () => {
    return (
        <div className="container text-center my-4 pb-4">
            <ActionInfoField/>
            <Row className={"justify-content-center"}>
                <Col lg={6} md={12} className={"mb-3"}>
                    <img
                        src={"https://lh5.googleusercontent.com/p/AF1QipPCLyBnRv9Hpg6wB0hs0SqXr6p65lSRYv6Fpt4V=s483-k-no"}
                        alt={"Hospůdka"}
                        className={"img-fluid rounded mb-3"}
                        loading={"lazy"}
                    />
                </Col>
                <Col lg={6} md={12} className={"mb-3"}>
                    <GoogleMap/>
                </Col>
            </Row>
            <p className={"mb-4"}>Naše nová hospůdka je ideálním místem pro všechny, kdo hledají skvělou atmosféru, chutné
                jídlo a příjemné
                ceny. Přijďte si pochutnat na našem denním menu během pracovního dne nebo si večer vychutnejte naše
                speciality, jako jsou lahodné burgery a grilované maso.</p>
            <OpeningHours/>
        </div>
    );
};

export default About;
