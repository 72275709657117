import {onValue, ref} from "firebase/database";
import {ActionInfoProps} from "../types";
import {database} from "../../../firebaseConfig";

export const fetchActionsData = (path: string, callback: (data: ActionInfoProps[]) => void) => {
    const actionsRef = ref(database, path);

    onValue(actionsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            callback(data);
        }
    });
};
