import React, { useEffect, useState } from 'react';
import './DrinksMenu.css';
import { DrinksMenuProps, DrinkItem } from "./type";
import {fetchDrinksData} from "../editComponents/editableDrinks/fetchDrinksData";

// Mapování klíčů kategorií na české názvy
const categoryLabels: { [key in keyof DrinksMenuProps]: string } = {
    beer: "Pivo",
    nonAlcoholic: "Nealko",
    hotDrinks: "Teplé nápoje",
    vine: "Víno",
    rum: "Rum",
    whiskey: "Whiskey",
    others: "Ostatní",
    snacks: "Pochutiny"
};

const DrinksMenu: React.FC = () => {
    const [drinksData, setDrinksData] = useState<DrinksMenuProps | null>(null);

    useEffect(() => {
        // Načítáme data z Firebase
        fetchDrinksData('drinksData', (data) => {
            if (data) {
                setDrinksData(data);
            }
        });
    }, []);

    if (!drinksData) {
        return <p>Načítání...</p>;
    }

    return (
        <section id="drinks-menu">
            <h2>Jirčanská hospůdka Na Návsi</h2>
            {Object.entries(drinksData).map(([category, items]) => (
                <React.Fragment key={category}>
                    <h3>{categoryLabels[category as keyof DrinksMenuProps]}</h3>
                    <ul>
                        {(items as DrinkItem[]).map((item) => (
                            <li key={item.id}>
                                <b>{item.name}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </section>
    );
};

export default DrinksMenu;
