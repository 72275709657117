import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ActionInfoProps} from './types';

const ActionInfo: React.FC<ActionInfoProps> = ({id, headerText, infoText}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div className="text-white p-4 rounded shadow-sm text-center my-4"
             style={{
                 transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                 transform: isHovered ? 'translateY(-10px)' : 'translateY(0)',
                 boxShadow: isHovered
                     ? '0 12px 16px rgba(0, 0, 0, 0.2)'
                     : '0 4px 8px rgba(0, 0, 0, 0.1)',
                 maxWidth: '100%',
                 backgroundColor: '#228B22'
             }}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             id={id}
        >
            <h2 className="mb-2"><b>{headerText}</b></h2>
            <p>{infoText}</p>
        </div>
    );
};

export default ActionInfo;
