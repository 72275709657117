import {Accordion, Button, ListGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {get, ref, set} from "firebase/database";
import {database} from "../../../firebaseConfig";
import MenuItemRow from "../MenuItem";
import {DrinkItem, DrinksMenuProps} from "../../drinks/type";

interface EditableDrinksMenuProps {
    data: DrinksMenuProps;
    onSave: (data: DrinksMenuProps) => void;
}

const EditableDrinksMenu: React.FC<EditableDrinksMenuProps> = ({data, onSave}) => {
    const [drinksData, setDrinksData] = useState<DrinksMenuProps>(data);

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(database, 'drinksData');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const fetchedData = snapshot.val();
                setDrinksData(fetchedData as DrinksMenuProps);
            }
        };
        fetchData();
    }, []);

    const moveItem = (categoryKey: string, dragIndex: number, hoverIndex: number) => {
        if (!(categoryKey in drinksData)) return;

        const updatedData = {...drinksData};
        const categoryItems = updatedData[categoryKey as keyof DrinksMenuProps]; // Přetypujeme na klíč typu DrinksMenuProps

        if (categoryItems) {
            const [draggedItem] = categoryItems.splice(dragIndex, 1);
            categoryItems.splice(hoverIndex, 0, draggedItem);
            setDrinksData(updatedData);
        }
    };


    const handleSave = async () => {
        await set(ref(database, 'drinksData'), drinksData);
        onSave(drinksData);
    };

    const handleAddItem = (categoryKey: keyof DrinksMenuProps) => {
        const updatedData = {...drinksData};
        updatedData[categoryKey].push({
            id: Date.now().toString(),
            name: 'Nová položka',
            price: '0,-',
        });
        setDrinksData(updatedData);
    };

    const handleDeleteItem = (categoryKey: keyof DrinksMenuProps, itemIndex: number) => {
        const updatedData = {...drinksData};
        updatedData[categoryKey].splice(itemIndex, 1);
        setDrinksData(updatedData);
    };

    const handleItemChange = (categoryKey: keyof DrinksMenuProps, itemIndex: number, key: 'name' | 'price', value: string) => {
        const updatedData = {...drinksData};
        updatedData[categoryKey][itemIndex][key] = value;
        setDrinksData(updatedData);
    };

    const categoryLabels: Record<keyof DrinksMenuProps, string> = {
        beer: "Pivo",
        nonAlcoholic: "Nealko",
        hotDrinks: "Teplé nápoje",
        vine: "Víno",
        rum: "Rum",
        whiskey: "Whiskey",
        others: "Ostatní",
        snacks: "Pochutiny",
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Accordion>
                {Object.entries(drinksData).map(([categoryKey, items]) => (
                    <Accordion.Item eventKey={categoryKey} key={categoryKey}>
                        <Accordion.Header>{categoryLabels[categoryKey as keyof DrinksMenuProps]}</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                {(items as DrinkItem[]).map((item, itemIndex) => (
                                    <MenuItemRow
                                        key={item.id}
                                        item={item}
                                        categoryIndex={categoryKey}
                                        itemIndex={itemIndex}
                                        moveItem={moveItem}
                                        onChange={(key, value) => handleItemChange(categoryKey as keyof DrinksMenuProps, itemIndex, key, value)}
                                        onDelete={() => handleDeleteItem(categoryKey as keyof DrinksMenuProps, itemIndex)}
                                    />
                                ))}
                            </ListGroup>
                            <Button variant="success"
                                    onClick={() => handleAddItem(categoryKey as keyof DrinksMenuProps)}
                                    className="mt-3">
                                Přidat položku
                            </Button>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Button variant="primary" onClick={handleSave} className="mt-4">
                Uložit změny
            </Button>
        </DndProvider>
    );
};

export default EditableDrinksMenu;
