import EditableMenu from "./EditableMenu";
import {menuDataDef} from "../../menu/menuData";
import {handleSave} from "./handleSave";
import React, {useEffect, useState} from "react";
import {MenuProps} from "../../menu/type";
import {fetchMenuData} from "./fetchMenuData";

const EditableMenuField = () => {
    const [menuData, setMenuData] = useState<MenuProps | null>(null);

    useEffect(() => {
        fetchMenuData('menuData', setMenuData);
    }, []);

    return (
        <div className="container mt-5 mb-4">
            <EditableMenu data={menuData ? menuData : menuDataDef} onSave={handleSave}/>
        </div>
    )
}

export default EditableMenuField;