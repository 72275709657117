import React, {useEffect, useState} from 'react';
import './Menu.css';
import {MenuProps} from "./type";
import {database} from "../../firebaseConfig";
import { ref, onValue } from 'firebase/database';


const Menu: React.FC = () => {
    const [data, setData] = useState<MenuProps>({
        menu: [],
        soups: [],
        mainCourses: [],
        desserts: [],
        snack: []
    });

    useEffect(() => {
        const menuRef = ref(database, 'menuData');
        onValue(menuRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setData(data);
            }
        });
    }, []);

    const { soups, mainCourses, desserts, snack } = data;

    return (
        <section id="menu">
            {/* Polévka Sekce */}
            {soups.length > 0 && (
                <>
                    <h3>Polévka</h3>
                    <ul>
                        {soups.map((item, index) => (
                            <li key={index}>
                                <b>{item.soup}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {/* Hlavní jídla Sekce */}
            {mainCourses.length > 0 && (
                <>
                    <h3>Hlavní jídla</h3>
                    <ul>
                        {mainCourses.map((item, index) => (
                            <li key={index}>
                                <b>{item.mainCourse}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {/* Dezerty Sekce */}
            {desserts && desserts.length > 0 && (
                <>
                    <h3>Dezerty</h3>
                    <ul>
                        {desserts.map((item, index) => (
                            <li key={index}>
                                <b>{item.dessertName}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {/* K pivu Sekce */}
            {snack.length > 0 && (
                <>
                    <h3>K pivu</h3>
                    <ul>
                        {snack.map((item, index) => (
                            <li key={index}>
                                <b>{item.snackName}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </section>
    );
};

export default Menu;