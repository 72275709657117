import React, {useEffect, useState} from "react";
import {Button, ListGroup} from "react-bootstrap";
import {get, ref, set} from "firebase/database";
import {ActionInfoProps, EditableActionsProps} from "../types";
import {database} from "../../../firebaseConfig";
import ActionItemRow from "./ActionItemRow";


const EditableActions: React.FC<EditableActionsProps> = ({data, onSave}) => {
    const [actions, setActions] = useState<ActionInfoProps[]>(data);

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(database, 'actions');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                setActions(snapshot.val());
            }
        };

        fetchData();
    }, []);

    const handleSave = async () => {
        await set(ref(database, 'actions'), actions);
        onSave(actions);
    };

    const handleAddAction = () => {
        const newAction: ActionInfoProps = {
            id: Date.now().toString(),
            headerText: 'Nová akce',
            infoText: 'Popis nové akce',
        };
        setActions([...actions, newAction]);
    };

    const handleDeleteAction = (actionIndex: number) => {
        const updatedActions = actions.filter((_, index) => index !== actionIndex);
        setActions(updatedActions);
    };

    const handleActionChange = (actionIndex: number, key: 'headerText' | 'infoText', value: string) => {
        const updatedActions = [...actions];
        updatedActions[actionIndex][key] = value;
        setActions(updatedActions);
    };

    return (
        <div>
            <ListGroup>
                {actions.map((action, actionIndex) => (
                    <ActionItemRow
                        key={action.id}
                        action={action}
                        actionIndex={actionIndex}
                        onChange={(key, value) => handleActionChange(actionIndex, key, value)}
                        onDelete={() => handleDeleteAction(actionIndex)}
                    />
                ))}
            </ListGroup>
            <Button variant="success" onClick={handleAddAction} className="mt-3 m-1">
                Přidat akci
            </Button>
            <Button variant="primary" onClick={handleSave} className="mt-3 m-1">
                Uložit změny
            </Button>
        </div>
    );
};

export default EditableActions;
