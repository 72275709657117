import React from 'react';

const GoogleMap: React.FC = () => {
    return (
        <div className="google-map mb-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.663302380002!2d14.504308116016942!3d49.93538797940621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9550b84e4e31%3A0x92953dd1d1eb4b99!2sHlavn%C3%AD%203%2C%20252%2044%20Ps%C3%A1ry-Doln%C3%AD%20Jir%C4%8Dany%2C%20%C4%8Czechia!5e0!3m2!1sen!2sus!4v1629461462801!5m2!1sen!2sus"
                width="100%"
                height="370px"
                style={{ border: 0 }}
                allowFullScreen={undefined}
                loading="lazy"
                title="Google Map"
            ></iframe>
        </div>
    );
};

export default GoogleMap;
