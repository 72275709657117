import React from "react";
import {Button, Form, ListGroup} from "react-bootstrap";
import {ActionItemRowProps} from "../types";

const ActionItemRow: React.FC<ActionItemRowProps> = ({action, actionIndex, onChange, onDelete}) => {
    return (
        <ListGroup.Item as="li" className="d-flex flex-column align-items-start p-0 border-0">
            <Form.Group className="mb-2 w-100">
                <Form.Label>Nadpis akce</Form.Label>
                <Form.Control
                    type="text"
                    value={action.headerText}
                    onChange={(e) => onChange('headerText', e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-2 w-100">
                <Form.Label>Popis akce</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={window.innerWidth > 720 ? 3 : 10}
                    value={action.infoText}
                    onChange={(e) => onChange('infoText', e.target.value)}
                />
            </Form.Group>
            <Button variant="danger" onClick={onDelete}>Smazat</Button>
        </ListGroup.Item>
    );
};

export default ActionItemRow;
