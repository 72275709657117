import React, {useEffect, useState} from "react";
import {ActionInfoProps} from "./types";
import ActionInfo from "./ActionInfo";
import {onValue, ref} from "firebase/database";
import {database} from "../../firebaseConfig";

const ActionInfoField: React.FC = () => {
    const [actions, setActions] = useState<ActionInfoProps[]>([]);

    useEffect(() => {
        const fetchActions = () => {
            const actionsRef = ref(database, 'actions');
            onValue(actionsRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setActions(Object.values(data));
                }
            });
        };

        fetchActions();
    }, []);
    return (
        <>
            {
                actions.map((action, index) => (
                        <ActionInfo
                            key={action.id}
                            id={action.id}
                            headerText={action.headerText}
                            infoText={action.infoText}/>
                    )
                )
            }
        </>
    )
}

export default ActionInfoField