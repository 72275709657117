import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../firebaseConfig";

const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            // Firebase login pomocí emailu a hesla
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/admin/editable-menu"); // Přesměrujeme uživatele na chráněnou stránku po úspěšném přihlášení
        } catch (err: any) {
            setError("Chyba při přihlášení: " + err.message);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Přihlášení</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleLogin}>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Heslo</label>
                    <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    Přihlásit se
                </button>
            </form>
        </div>
    );
};

export default Login;
