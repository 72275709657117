import {useNavigate} from "react-router-dom";
import {auth} from "../firebaseConfig";
import {Button} from "react-bootstrap";
import {signOut} from "firebase/auth";

const LogoutButton: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/'); // Přesměrování na hlavní stránku po odhlášení
        } catch (error) {
            console.error("Chyba při odhlašování:", error);
        }
    };

    return (
        <Button variant="outline-light" onClick={handleLogout}>
            Odhlásit se
        </Button>
    );
};

export default LogoutButton;