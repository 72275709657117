import EditableDrinksMenu from "./EditableDrinksMenu";
import {handleSaveDrinks} from "./handleSaveDrinks";
import React, {useEffect, useState} from "react";
import {fetchDrinksData} from "./fetchDrinksData";
import {DrinksMenuProps} from "../../drinks/type";


const EditableDrinksMenuField: React.FC = () => {
    const [drinksData, setDrinksData] = useState<DrinksMenuProps>();

    useEffect(() => {
        // Načítáme data z Firebase
        fetchDrinksData('drinksData', (data) => {
            if (data) {
                setDrinksData(data);
            }
        });
    }, []);
    return (
        <div className="container mt-5 mb-4">
            {
                drinksData &&
                <EditableDrinksMenu data={drinksData} onSave={handleSaveDrinks}/>
            }
        </div>
    );
};

export default EditableDrinksMenuField;
