import { ref, set } from "firebase/database";
import { database } from "../../../firebaseConfig";
import {DrinksMenuProps} from "../../drinks/type";

export const handleSaveDrinks = async (data: DrinksMenuProps) => {
    try {
        const drinksRef = ref(database, 'drinksData');
        await set(drinksRef, data);
        console.log("Uložená data", data);
        alert('Data byla úspěšně uložena do Firebase!');
    } catch (error) {
        console.error("Chyba při ukládání dat: ", error);
        alert('Došlo k chybě při ukládání dat. Zkuste to prosím znovu.');
    }
};
