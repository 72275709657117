import React, {useEffect, useState} from "react";
import {ActionInfoProps} from "../types";
import {fetchActionsData} from "./fetchActionsData";
import {handleSaveActions} from "./handleSaveActions";
import EditableActions from "./EditableActions";


const EditableActionsField = () => {
    const [actionsData, setActionsData] = useState<ActionInfoProps[]>([]);

    useEffect(() => {
        fetchActionsData('actions', setActionsData);
    }, []);

    return (
        <div className="container mt-5 mb-4">
            <EditableActions data={actionsData} onSave={handleSaveActions}/>
        </div>
    );
};

export default EditableActionsField;
