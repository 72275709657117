import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Alert } from 'react-bootstrap';
import { ref, get, set } from "firebase/database";
import {database} from "../../firebaseConfig";

interface OpeningHoursData {
    [day: string]: string;
}

// Pořadí dnů v týdnu
const daysOrder = ["Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota", "Neděle"];

const ManageOpeningHours: React.FC = () => {
    const [openingHours, setOpeningHours] = useState<OpeningHoursData | null>(null);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Načítání dat z Firebase
    useEffect(() => {
        const fetchOpeningHours = async () => {
            try {
                const snapshot = await get(ref(database, 'openingHours'));
                if (snapshot.exists()) {
                    setOpeningHours(snapshot.val());
                } else {
                    setOpeningHours({});
                }
            } catch (error) {
                setError("Chyba při načítání dat.");
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchOpeningHours();
    }, []);

    // Funkce pro aktualizaci otevírací doby konkrétního dne
    const handleInputChange = (day: string, value: string) => {
        setOpeningHours((prev) => ({
            ...prev,
            [day]: value,
        }));
    };

    // Ukládání upravených dat do Firebase
    const handleSave = async () => {
        if (openingHours) {
            try {
                await set(ref(database, 'openingHours'), openingHours);
                setSuccess(true);
                setTimeout(() => setSuccess(false), 3000);
            } catch (error) {
                setError("Chyba při ukládání dat.");
                console.error(error);
            }
        }
    };

    if (loading) return <p>Načítání dat...</p>;
    if (error) return <Alert variant="danger">{error}</Alert>;

    return (
        <section className="manage-opening-hours container mt-5">
            <h2 className="text-center mb-4">Správa otevírací doby</h2>

            {success && <Alert variant="success">Úspěšně uloženo!</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form>
                {daysOrder.map((day) => (
                    <Form.Group as={Row} className="mb-3" key={day}>
                        <Form.Label column sm="3" className="text-end">
                            {day}:
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                type="text"
                                value={openingHours?.[day] || ""}
                                onChange={(e) => handleInputChange(day, e.target.value)}
                                placeholder="Např. 11:00 - 22:00 nebo Zavřeno"
                            />
                        </Col>
                    </Form.Group>
                ))}
                <div className="text-center mt-4">
                    <Button variant="primary" onClick={handleSave}>
                        Uložit změny
                    </Button>
                </div>
            </Form>
        </section>
    );
};

export default ManageOpeningHours;
