import {ref, set} from "firebase/database";
import {ActionInfoProps} from "../types";
import {database} from "../../../firebaseConfig";


export const handleSaveActions = async (data: ActionInfoProps[]) => {
    try {
        const actionsRef = ref(database, 'actions');
        await set(actionsRef, data);
        console.log("Uložená data", data);
        alert('Data byla úspěšně uložena do Firebase!');
    } catch (error) {
        console.error("Chyba při ukládání dat: ", error);
        alert('Došlo k chybě při ukládání dat. Zkuste to prosím znovu.');
    }
};
